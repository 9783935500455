import { useEffect, useState } from "react";
import AutherAndDate from "../components/dateandauther";
import { Link, useLocation } from "react-router-dom";
import useFetch from "../hooks/usefetch";

const ArticleLayout = () => {
  const newsUrl = "https://api.kbclive.net/last-ten-articles";
  const location = useLocation();

  const { getData: getarticle, data: article } = useFetch();

  useEffect(() => {
    getarticle(newsUrl);
  }, []);

  return (
    <div className="  section">
      <div
        style={{ whiteSpace: "nowrap" }}
        className="flex items-center gap-3 mb-5"
      >
        <h1
          className={`text-2xl   font-bold font-calib-r ${
            location.pathname === "/health" ? "text-green-6" : "text-green"
          }`}
        >
          زیاتر بخوێنەوە
        </h1>
        <div
          className={`  w-full h-[3px] ${
            location.pathname === "/health" ? "bg-green-6" : "bg-green"
          }`}
        ></div>
      </div>
      <div className=" mb-8  grid grid-cols-2 gap-5 md:grid-cols-1">
        {article &&
          article.map((article) => (
            <div
              key={article.news_id}
              className=" bg-white p-3 border-2 border-white grid grid-cols-3 items-center gap-5 sm:p-1 md:gap-2"
            >
              <div className=" md:place-self-center">
                <img
                  className=" h-40 w-40  sm:w-24 sm:h-24 rounded-full   object-cover"
                  src={`https://api.kbclive.net/uploads/${article?.image}`}
                  alt={article?.news_title}
                />
              </div>
              <div className=" p-5 col-start-2 col-end-4 flex flex-col md:p-2 ">
                <Link
                  className=" px-3 duration-300 py-2 bg-green text-white font-calib-b text-2xl hover:text-gray-800 md:px-1 sm:text-xl"
                  to={`/details/${article?.news_id}`}
                >
                  {article.news_title.length > 45
                    ? `${article.news_title.slice(0, 40)}...`
                    : article.news_title}
                </Link>
                <div className="  mt-auto">
                  <AutherAndDate news={article} />
                </div>
              </div>
            </div>
          ))}
      </div>

      <div className="text-center ">
        <Link
          to="/article"
          className="px-5 py-1 font-calib-b   text-xl  text-black border-2 border-green hover:bg-green hover:text-white"
        >
          هەمووی ببینە
        </Link>
      </div>
    </div>
  );
};

export default ArticleLayout;
