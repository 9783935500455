const AutherAndDate = ({ news }) => {
  return (
    <div className="">
      <span className=" font-calib-n">{news?.news_date?.substring(0, 10)}</span>

      <span
        className="   font-calib-r text-xl 
      "
      >
        {news?.news_auther && ` / ${news?.news_auther}`}
      </span>
    </div>
  );
};

export default AutherAndDate;
