import { Swiper, SwiperSlide } from "swiper/react";

import insocial from "../images/insocial.jpg";
import "./programswiper.css";
import health from "../images/health.jpg";
import speak from "../images/speak.jpg";
import sport from "../images/sport.jpg";
import "swiper/css";
import "swiper/css/pagination";

import { Autoplay, Pagination } from "swiper";
import { Link } from "react-router-dom";
import useFetch from "../hooks/usefetch";
import { useEffect } from "react";
import YoutubeThumnail from "./youtube-thumnail";
import AutherAndDate from "./dateandauther";
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function ProgramSwiper() {
  const healthUrl = "https://api.kbclive.net/last-two-health-program";
  const sportUrl = "https://api.kbclive.net/last-two-sport-program";
  const speakUrl = "https://api.kbclive.net/last-two-speak-program";
  const insocialUrl = "https://api.kbclive.net/last-two-insocial-program";

  const { getData: getSport, data: sportdata } = useFetch();
  const { getData: getHealth, data: healthdata } = useFetch();
  const { getData: getSpeak, data: speakdata } = useFetch();
  const { getData: getInSocial, data: inSocialdata } = useFetch();

  useEffect(() => {
    getSport(sportUrl);
    getHealth(healthUrl);
    getSpeak(speakUrl);
    getInSocial(insocialUrl);
  }, []);

  return (
    <>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,

          className: "my-pagination",
        }}
        modules={[Autoplay, Pagination]}
        className="program-swiper"
      >
        <SwiperSlide>
          <div
            style={{
              background: `linear-gradient(
              rgba(0, 0, 0, 0), 
              rgba(0, 0, 0, 0)
            ), url(${insocial})`,
              backgroundSize: "cover",

              backgroundPosition: "70% 100%",
            }}
            className=" relative w-full h-[80vh] md:h-[50vh] xs:h-[30vh]"
          >
            <div className="absolute max-w-2xl left-28 top-36 md:top- md:left-10 xs:top-20 xs:left-5 ">
              <div
                style={{
                  background: `linear-gradient(
    rgba(0, 0, 0, 0.8), 
    rgba(0, 0, 0, 0.8)
  )`,
                }}
                className=" p-5 mb-5 xs:p-3"
              >
                <h1 className=" text-white font-calib-b mb-5 text-5xl sm:text-2xl xs:mb-3">
                  بەرنامەی لە سۆشیال میدیا
                </h1>
                <Link
                  to="/program-social"
                  className=" px-4 py-2 bg-green text-white font-calib-r  text-2xl hover:bg-white hover:text-green sm:text-lg"
                >
                  بینینی بەرنامەکان
                </Link>
              </div>
              <div className=" grid grid-cols-2 gap-5  md:hidden">
                {inSocialdata &&
                  inSocialdata?.slice(0, 2).map((inSocialdata) => (
                    <div
                      key={inSocialdata.news_id}
                      className=" p-2 bg-black text-white"
                    >
                      <div className=" hover:text-green">
                        <Link to={`/details/${inSocialdata.news_id}`}>
                          <LazyLoadImage
                            src={`https://api.kbclive.net/uploads/${inSocialdata?.image}`}
                            alt=""
                            effect="blur"
                            placeholderSrc="path/to/placeholder-image.jpg" // Optional placeholder image while loading
                          />
                        </Link>

                        <div className=" px-4 py-2 flex flex-col">
                          <div className=" -mt-[4px] text-green font-calib-b mb-3">
                            <AutherAndDate news={inSocialdata} />
                          </div>
                          <Link
                            to={`/details/${inSocialdata.news_id}`}
                            className="text-2xl font-calib-b  hover:text-green sm:text-xl"
                          >
                            {inSocialdata.news_title}
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div
            style={{
              background: `linear-gradient(
              rgba(0, 0, 0, 0), 
              rgba(0, 0, 0, 0)
            ), url(${sport})`,
              backgroundSize: "cover",

              backgroundPosition: "50% 100%",
            }}
            className=" relative w-full h-[80vh] md:h-[50vh] xs:h-[30vh]"
          >
            <div className="absolute max-w-2xl left-28 top-36 md:top- md:left-10 xs:top-20 xs:left-5 ">
              <div
                style={{
                  background: `linear-gradient(
    rgba(0, 0, 0, 0.8), 
    rgba(0, 0, 0, 0.8)
  )`,
                }}
                className=" p-5 mb-5 xs:p-3"
              >
                <h1 className=" text-white font-calib-b mb-5 text-5xl sm:text-2xl xs:mb-3">
                  بەرنامەی شیکاری وەرزشی
                </h1>
                <Link
                  to="/program-sport"
                  className=" px-4 py-2 bg-green text-white font-calib-r  text-2xl hover:bg-white hover:text-green sm:text-lg"
                >
                  بینینی بەرنامەکان
                </Link>
              </div>
              <div className=" grid grid-cols-2 gap-5  md:hidden">
                {sportdata &&
                  sportdata?.slice(0, 2).map((sportdata) => (
                    <div
                      key={sportdata.news_id}
                      className=" p-2 bg-black text-white"
                    >
                      <div className=" hover:text-green">
                        <Link to={`/details/${sportdata.news_id}`}>
                          <LazyLoadImage
                            src={`https://api.kbclive.net/uploads/${sportdata?.image}`}
                            alt=""
                            effect="blur"
                            placeholderSrc="path/to/placeholder-image.jpg" // Optional placeholder image while loading
                          />
                        </Link>

                        <div className=" px-4 py-2 flex flex-col">
                          <div className=" -mt-[4px] text-green font-calib-b mb-3">
                            <AutherAndDate news={sportdata} />
                          </div>
                          <Link
                            to={`/details/${sportdata.news_id}`}
                            className="text-2xl font-calib-b  hover:text-green sm:text-xl"
                          >
                            {sportdata.news_title}
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div
            style={{
              background: `linear-gradient(
              rgba(0, 0, 0, 0), 
              rgba(0, 0, 0, 0)
            ), url(${health})`,
              backgroundSize: "cover",

              backgroundPosition: "50% 100%",
            }}
            className=" relative w-full h-[80vh] md:h-[50vh] xs:h-[30vh]"
          >
            <div className="absolute max-w-2xl left-28 top-36 md:top- md:left-10 xs:top-20 xs:left-5 ">
              <div
                style={{
                  background: `linear-gradient(
    rgba(0, 0, 0, 0.8), 
    rgba(0, 0, 0, 0.8)
  )`,
                }}
                className=" p-5 mb-5 xs:p-3"
              >
                <h1 className=" text-white font-calib-b mb-5 text-5xl sm:text-2xl xs:mb-3">
                  بەرنامەی ژیری تەندروستی
                </h1>
                <Link
                  to="/program-health"
                  className=" px-4 py-2 bg-green text-white font-calib-r  text-2xl hover:bg-white hover:text-green sm:text-lg"
                >
                  بینینی بەرنامەکان
                </Link>
              </div>
              <div className=" grid grid-cols-2 gap-5  md:hidden">
                {healthdata &&
                  healthdata?.slice(0, 2).map((healthdata) => (
                    <div
                      key={healthdata.news_id}
                      className=" p-2 bg-black text-white"
                    >
                      <div className=" hover:text-green">
                        <Link to={`/details/${healthdata.news_id}`}>
                          <LazyLoadImage
                            src={`https://api.kbclive.net/uploads/${healthdata?.image}`}
                            alt=""
                            effect="blur"
                            placeholderSrc="path/to/placeholder-image.jpg" // Optional placeholder image while loading
                          />
                        </Link>

                        <div className=" px-4 py-2 flex flex-col">
                          <div className=" -mt-[4px] text-green font-calib-b mb-3">
                            <AutherAndDate news={healthdata} />
                          </div>
                          <Link
                            to={`/details/${healthdata.news_id}`}
                            className="text-2xl font-calib-b  hover:text-green sm:text-xl"
                          >
                            {healthdata.news_title}
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div
            style={{
              background: `linear-gradient(
              rgba(0, 0, 0, 0), 
              rgba(0, 0, 0, 0)
            ), url(${speak})`,
              backgroundSize: "cover",

              backgroundPosition: "50% 100%",
            }}
            className=" relative w-full h-[80vh] md:h-[50vh] xs:h-[30vh]"
          >
            <div className="absolute max-w-2xl left-28 top-36 md:top- md:left-10 xs:top-20 xs:left-5 ">
              <div
                style={{
                  background: `linear-gradient(
    rgba(0, 0, 0, 0.8), 
    rgba(0, 0, 0, 0.8)
  )`,
                }}
                className=" p-5 mb-5 xs:p-3"
              >
                <p className=" text-white font-calib-b mb-5 text-5xl sm:text-2xl xs:mb-3">
                  بەرنامەی گوتار
                </p>
                <Link
                  to="/program-discourse"
                  className=" px-4 py-2 bg-green text-white font-calib-r  text-2xl hover:bg-white hover:text-green sm:text-lg"
                >
                  بینینی بەرنامەکان
                </Link>
              </div>
              <div className=" grid grid-cols-2 gap-5  md:hidden">
                {speakdata &&
                  speakdata?.slice(0, 2).map((speakdata) => (
                    <div
                      key={speakdata.news_id}
                      className=" p-2 bg-black text-white"
                    >
                      <div className=" hover:text-green">
                        <Link to={`/details/${speakdata.news_id}`}>
                          <LazyLoadImage
                            src={`https://api.kbclive.net/uploads/${speakdata?.image}`}
                            alt=""
                            effect="blur"
                            placeholderSrc="path/to/placeholder-image.jpg" // Optional placeholder image while loading
                          />
                        </Link>

                        <div className=" px-4 py-2 flex flex-col">
                          <div className=" -mt-[4px] text-green font-calib-b mb-3">
                            <AutherAndDate news={speakdata} />
                          </div>
                          <Link
                            to={`/details/${speakdata.news_id}`}
                            className="text-2xl font-calib-b  hover:text-green sm:text-xl"
                          >
                            {speakdata.news_title}
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[Autoplay, Pagination]}
        className="program-swiper "
      >
        <SwiperSlide>
          <div className=" px-3 hidden md:block ">
            <div className=" flex flex-col gap-5 ">
              {inSocialdata &&
                inSocialdata?.slice(0, 2).map((inSocialdata) => (
                  <div
                    key={inSocialdata.news_id}
                    className=" p-2 bg-black text-white"
                  >
                    <div className=" hover:text-green">
                      <Link to={`/details/${inSocialdata.news_id}`}>
                        <LazyLoadImage
                          src={`https://api.kbclive.net/uploads/${inSocialdata?.image}`}
                          alt=""
                          effect="blur"
                          placeholderSrc="path/to/placeholder-image.jpg" // Optional placeholder image while loading
                        />
                      </Link>

                      <div className=" px-4 py-2 flex flex-col">
                        <div className=" -mt-[4px] text-green font-calib-b mb-3">
                          <AutherAndDate news={inSocialdata} />
                        </div>
                        <Link
                          to={`/details/${inSocialdata.news_id}`}
                          className="text-2xl font-calib-b  hover:text-green sm:text-xl"
                        >
                          {inSocialdata.news_title}
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </SwiperSlide>{" "}
        <SwiperSlide>
          <div className=" px-3 hidden md:block ">
            <div className=" flex flex-col gap-5 ">
              {sportdata &&
                sportdata?.slice(0, 2).map((sportdata) => (
                  <div
                    key={sportdata.news_id}
                    className=" p-2 bg-black text-white"
                  >
                    <div className=" hover:text-green">
                      <Link to={`/details/${sportdata.news_id}`}>
                        <LazyLoadImage
                          src={`https://api.kbclive.net/uploads/${sportdata?.image}`}
                          alt=""
                          effect="blur"
                          placeholderSrc="path/to/placeholder-image.jpg" // Optional placeholder image while loading
                        />
                      </Link>

                      <div className=" px-4 py-2 flex flex-col">
                        <div className=" -mt-[4px] text-green font-calib-b mb-3">
                          <AutherAndDate news={sportdata} />
                        </div>
                        <Link
                          to={`/details/${sportdata.news_id}`}
                          className="text-2xl font-calib-b  hover:text-green sm:text-xl"
                        >
                          {sportdata.news_title}
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </SwiperSlide>{" "}
        <SwiperSlide>
          <div className=" px-3 hidden md:block ">
            <div className=" flex flex-col gap-5 ">
              {healthdata &&
                healthdata?.slice(0, 2).map((healthdata) => (
                  <div
                    key={healthdata.news_id}
                    className=" p-2 bg-black text-white"
                  >
                    <div className=" hover:text-green">
                      <Link to={`/details/${healthdata.news_id}`}>
                        <LazyLoadImage
                          src={`https://api.kbclive.net/uploads/${healthdata?.image}`}
                          alt=""
                          effect="blur"
                          placeholderSrc="path/to/placeholder-image.jpg" // Optional placeholder image while loading
                        />
                      </Link>

                      <div className=" px-4 py-2 flex flex-col">
                        <div className=" -mt-[4px] text-green font-calib-b mb-3">
                          <AutherAndDate news={healthdata} />
                        </div>
                        <Link
                          to={`/details/${healthdata.news_id}`}
                          className="text-2xl font-calib-b  hover:text-green sm:text-xl"
                        >
                          {healthdata.news_title}
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </SwiperSlide>{" "}
        <SwiperSlide>
          <div className=" px-3 hidden md:block ">
            <div className=" flex flex-col gap-5 ">
              {speakdata &&
                speakdata?.slice(0, 2).map((speakdata) => (
                  <div
                    key={speakdata.news_id}
                    className=" p-2 bg-black text-white"
                  >
                    <div className=" hover:text-green">
                      <Link to={`/details/${speakdata.news_id}`}>
                        <LazyLoadImage
                          src={`https://api.kbclive.net/uploads/${speakdata?.image}`}
                          alt=""
                          effect="blur"
                          placeholderSrc="path/to/placeholder-image.jpg" // Optional placeholder image while loading
                        />
                      </Link>

                      <div className=" px-4 py-2 flex flex-col">
                        <div className=" -mt-[4px] text-green font-calib-b mb-3">
                          <AutherAndDate news={speakdata} />
                        </div>
                        <Link
                          to={`/details/${speakdata.news_id}`}
                          className="text-2xl font-calib-b  hover:text-green sm:text-xl"
                        >
                          {speakdata.news_title}
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
}
