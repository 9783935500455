import { Link } from "react-router-dom";
import AutherAndDate from "../components/dateandauther";
import { useEffect, useState } from "react";
import speak from "../images/speak.jpg";
import useFetch from "../hooks/usefetch";
import YoutubeThumnail from "../components/youtube-thumnail";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Loading from "../components/lodaing/loading";
const SpeakProgram = () => {
  const newsUrl = "https://api.kbclive.net/last-speak-program";

  const [visibleprograms, setVisibleprograms] = useState(9);
  const { getData: getprogram, data: program, isloading } = useFetch();

  const handleLoadMore = () => {
    setVisibleprograms((prevCount) => prevCount + 8);
  };

  useEffect(() => {
    getprogram(newsUrl);
  }, []);

   if (isloading) {
     return <Loading />;
   }
  return (
    <div>
      <Helmet>
        <title> بەرنامەی گوتار</title>
      </Helmet>
      <div
        style={{
          background: `linear-gradient(
              rgba(0, 0, 0, 0), 
              rgba(0, 0, 0, 0)
            ), url(${speak})`,
          backgroundSize: "cover",

          backgroundPosition: "50% 100%",
        }}
        className=" relative w-full h-[80vh] md:h-[50vh] xs:h-[30vh]"
      >
        <div className="absolute max-w-2xl left-28 top-36 md:top- md:left-10 xs:top-20 xs:left-5 ">
          <div
            style={{
              background: `linear-gradient(
                  rgba(0, 0, 0, 0.8), 
                      rgba(0, 0, 0, 0.8)
                 )`,
            }}
            className=" p-3 text-white md:hidden"
          >
            <h1 className=" text-green text-3xl font-calib-b mb-2">
              بەرنامەی گوتار
            </h1>
            <p className="  font-calib-r text-lg">
              گوتار بەرنامەیەکە تایبەتە بە بڵاوکردنەوەی بەها باڵاکانی دەوڵەتی
              مۆدێرن. یەکەم بەرنامەی گوتار لە نیسانی 2023 دەستی پێکرد. لەم
              بەرنامەیەدا، ھەر جارە و تیشك دەخەینە سەر لایەنێکی بنەماکانی گوتاری
              بەھاکانی دەوڵەتی مۆدێرن. ئازادیەکان و دەوڵەتی دامەزراوەیی و قانون
              و مافەکانی مرۆڤ.
            </p>
          </div>
        </div>
      </div>
      <div className=" py-4 px-3 hidden md:block">
        <h1 className=" text-3xl font-calib-b mb-3 text-green">
          بەرنامەی گوتار
        </h1>
        <p className="  font-calib-r text-xl flex flex-wrap">
          گوتار بەرنامەیەکە تایبەتە بە بڵاوکردنەوەی بەها باڵاکانی دەوڵەتی
          مۆدێرن. یەکەم بەرنامەی گوتار لە نیسانی 2023 دەستی پێکرد. لەم
          بەرنامەیەدا، ھەر جارە و تیشك دەخەینە سەر لایەنێکی بنەماکانی گوتاری
          بەھاکانی دەوڵەتی مۆدێرن. ئازادیەکان و دەوڵەتی دامەزراوەیی و قانون و
          مافەکانی مرۆڤ.
        </p>
      </div>
      <div className="section">
        <div
          style={{ whiteSpace: "nowrap" }}
          className="flex items-center gap-3 mb-5"
        >
          <h1 className="text-2xl  text-green font-bold font-calib-b">
            بەرنامەکان
          </h1>
          <div className="bg-green w-full h-[3px]"></div>
        </div>
        <div className=" mb-5  grid grid-cols-3 gap-5 md:grid-cols-2 xs:grid-cols-1">
          {program &&
            program?.slice(0, visibleprograms).map((program) => (
              <div key={program.news_id} className=" p-2 bg-black text-white">
                <div className=" hover:text-green">
                  <Link to={`/details/${program.news_id}`}>
                    <LazyLoadImage
                      src={`https://api.kbclive.net/uploads/${program?.image}`}
                      alt=""
                      effect="blur"
                      placeholderSrc="path/to/placeholder-image.jpg" // Optional placeholder image while loading
                    />
                  </Link>

                  <div className=" px-4 py-2 flex flex-col">
                    {program?.category_id === 4 && (
                      <h1 className=" text-lg text-white   font-calib-r ">
                        {program?.program_kind}
                      </h1>
                    )}
                    <div className=" -mt-[4px] text-green font-calib-b mb-3">
                      <AutherAndDate news={program} />
                    </div>
                    <Link
                      to={`/details/${program.news_id}`}
                      className="text-2xl font-calib-b  hover:text-green sm:text-xl"
                    >
                      {program.news_title}
                    </Link>
                  </div>
                </div>
              </div>
            ))}
        </div>
        {visibleprograms < program.length && (
          <div className="text-center">
            <button
              className="px-5 py-1 font-calib-b  text-xl  text-black border-2 border-green hover:bg-green hover:text-white"
              onClick={handleLoadMore}
            >
              زیاتر ببــــینە
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default SpeakProgram;
