import "./loading.css";

const Loading = () => {
  return (
    <div>
      <div className="flex justify-center items-center py-20 mt-10">
        <div id="square1" className="  grid grid-cols-2 gap-3">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
  );
};

export default Loading;
