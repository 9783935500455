import { useState, useEffect } from "react";
import useFetch from "../hooks/usefetch";
import articlebg from "../images/article-bg.jpg";
import articleimage from "../images/article.jpg";
import AutherAndDate from "../components/dateandauther";
import LastNews from "../layouts/lastnews";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import MultiMediaLayout from "../layouts/MultiMedia";
import ArticleLayout from "../layouts/articlelaout";
import Loading from "../components/lodaing/loading";
const Article = () => {
  const newsUrl = "https://api.kbclive.net/category/1";

  const { getData: getarticle, data: article, isloading } = useFetch();

  useEffect(() => {
    getarticle(newsUrl);
  }, []);

  const [visiblearticles, setVisiblearticles] = useState(15);
  const handleLoadMore = () => {
    setVisiblearticles((prevCount) => prevCount + 8);
  };

  if (isloading) {
    return <Loading />;
  }
  return (
    <div>
      <Helmet>
        <title>وتار</title>
      </Helmet>
      <div
        style={{
          background: `linear-gradient(
              rgba(0, 0, 0, 0), 
              rgba(0, 0, 0, 0)
            ), url(${articlebg})`,
          backgroundSize: " cover",
          backgroundPosition: "center center",
        }}
        className=""
      >
        <div className=" section">
          <h1 className="text-6xl mb-10 mt-14  text-white text-center font-calib-b ">
            وتار
          </h1>
          <div className=" grid grid-cols-2 gap-10 md:grid-cols-1">
            <div className=" p-3 border-2 border-white md:p-1">
              <div className="mb-5 ">
                <img
                  className="w-full h-52 object-cover"
                  src={articleimage}
                  alt={"article"}
                />
              </div>
              <div className=" grid grid-cols-3 gap-5 items-center md:gap-2">
                <div className=" md:place-self-center">
                  <img
                    className=" h-40 w-40 sm:h-24 sm:w-24 rounded-full   object-cover"
                    src={`https://api.kbclive.net/uploads/${article[0]?.image}`}
                    alt={article[0]?.news_title}
                  />
                </div>
                <div className=" p-5 col-start-2 col-end-4 flex flex-col md:p-0 ">
                  <Link
                    className=" px-3 py-2 bg-green text-white font-calib-b text-2xl hover:text-gray-800 md:px-1 sm:text-xl"
                    to={`/details/${article[0]?.news_id}`}
                  >
                    {article[0]?.news_title}
                  </Link>
                  <div className=" text-white mt-auto">
                    <AutherAndDate news={article[0]} />
                  </div>
                </div>
              </div>
            </div>
            <div className=" flex flex-col gap-10">
              {article &&
                article.slice(1, 3).map((article) => (
                  <div
                    key={article.news_id}
                    className=" p-3 border-2 border-white grid grid-cols-3 gap-5 items-center sm:p-1 md:gap-2"
                  >
                    <div className=" md:place-self-center">
                      <img
                        className=" h-40 w-40  sm:h-24 sm:w-24 rounded-full   object-cover"
                        src={`https://api.kbclive.net/uploads/${article?.image}`}
                        alt={article?.news_title}
                      />
                    </div>
                    <div className=" p-5 col-start-2 col-end-4 flex flex-col md:p-0 ">
                      <Link
                        className=" px-3 py-2 bg-green text-white font-calib-b text-2xl hover:text-gray-800 md:px-1 sm:text-xl"
                        to={`/details/${article?.news_id}`}
                      >
                        {article.news_title.length > 45
                          ? `${article.news_title.slice(0, 40)}...`
                          : article.news_title}
                      </Link>
                      <div className=" text-white mt-auto">
                        <AutherAndDate news={article} />
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      <div className=" py-10 bg-gray-100">
        <div className="  section">
          <div
            style={{ whiteSpace: "nowrap" }}
            className="flex items-center gap-3 mb-5"
          >
            <h1 className="text-2xl  text-green font-bold font-calib-b">
              زیاتر بخوێنەوە
            </h1>
            <div className="bg-green w-full h-[3px]"></div>
          </div>
          <div className=" mb-5  grid grid-cols-2 items-center gap-5 md:grid-cols-1">
            {article &&
              article.slice(3, visiblearticles).map((article) => (
                <div
                  key={article.news_id}
                  className=" bg-white p-3 border-2 border-white grid grid-cols-3 items-center gap-5 sm:p-1 md:gap-2"
                >
                  <div className=" md:place-self-center">
                    <img
                      className=" h-40 w-40  sm:w-24 sm:h-24 rounded-full   object-cover"
                      src={`https://api.kbclive.net/uploads/${article?.image}`}
                      alt={article?.news_title}
                    />
                  </div>
                  <div className=" p-5 col-start-2 col-end-4 flex flex-col md:p-2 ">
                    <Link
                      className=" px-3 duration-300 py-2 bg-green text-white font-calib-b text-2xl hover:text-gray-800 md:px-1 sm:text-xl"
                      to={`/details/${article?.news_id}`}
                    >
                      {article.news_title.length > 45
                        ? `${article.news_title.slice(0, 40)}...`
                        : article.news_title}
                    </Link>
                    <div className="  mt-auto">
                      <AutherAndDate news={article} />
                    </div>
                  </div>
                </div>
              ))}
          </div>
          {visiblearticles < article.length && (
            <div className="text-center">
              <button
                className="px-5 py-1 font-calib-b  text-xl  text-black border-2 border-green hover:bg-green hover:text-white"
                onClick={handleLoadMore}
              >
                زیاتر ببــــینە
              </button>
            </div>
          )}
        </div>
      </div>
      <MultiMediaLayout />

      <LastNews />
    </div>
  );
};

export default Article;
