import { useState, useEffect } from "react";
import useFetch from "../hooks/usefetch";
import multimediabg from "../images/multimedia-bg.jpg";
import AutherAndDate from "../components/dateandauther";
import LastNews from "../layouts/lastnews";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import YoutubeThumnail from "../components/youtube-thumnail";
import YoutubeVideo from "../components/youtubevideo";
import YoutubeThumnailForMultimedia from "../components/youtube-thumnail2";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Loading from "../components/lodaing/loading";
const MultiMedia = () => {
  const newsUrl = "https://api.kbclive.net/category/3";
  const programroute = "https://api.kbclive.net/last-six-program";

  const { getData: getNews, data: news } = useFetch();

  const [visiblemultimedias, setVisiblemultimedias] = useState(6);
  const { getData: getmultimedia, data: multimedia, isloading } = useFetch();

  const [visibleNews, setVisibleNews] = useState(15);

  const handleLoadMoreLastnews = () => {
    setVisibleNews((prevCount) => prevCount + 12);
  };

  useEffect(() => {
    getmultimedia(newsUrl);
    getNews(programroute);
  }, []);

  const handleLoadMore = () => {
    setVisiblemultimedias((prevCount) => prevCount + 6);
  };

  if (isloading) {
    return <Loading />;
  }

  return (
    <div className=" bg-black min-h-screen">
      <Helmet>
        <title>مەڵتی میدیا</title>
      </Helmet>
      <div
        style={{
          background: `linear-gradient(
              rgba(0, 0, 0, 0), 
              rgba(0, 0, 0, 0)
            ), url(${multimediabg})`,
          backgroundSize: " cover",
          height: "50vh",
          width: "100%",
        }}
        className=" bg-no-repeat"
      ></div>
      <div className=" section -mt-96 gap-8 flex flex-col sm:gap-4 ">
        <div>
          <h1 className="text-6xl mb-2  text-white text-center font-calib-b ">
            مەڵتی میدیا
          </h1>
        </div>
        <div className="grid grid-cols-2 gap-6 sm:grid-cols-1 sm:gap-3  ">
          {multimedia &&
            multimedia.slice(0, 2).map((item) => (
              <div
                key={item.news_id}
                className="p-3   border-r-[3px] text-white border-green bg-[#000000] grid grid-cols-2 md:grid-cols-1 sm:gap-2 hover:text-green "
              >
                <Link to={`/details/${item?.news_id}`}>
                  <LazyLoadImage
                    src={`https://api.kbclive.net/uploads/${item?.image}`}
                    alt=""
                    effect="blur"
                    placeholderSrc="path/to/placeholder-image.jpg" // Optional placeholder image while loading
                  />
                </Link>
                <div className="px-5 py-3 flex flex-col sm:px-1 ">
                  <Link
                    to={`/details/${item.news_id}`}
                    className="font-calib-b   text-2xl hover:text-green sm:text-xl"
                  >
                    {item.news_title}
                  </Link>

                  <div className=" text-gray-300 opacity-70 mt-auto  sm:mt-2">
                    <AutherAndDate news={item} />
                  </div>
                </div>
              </div>
            ))}
        </div>
        {multimedia[2] && (
          <div className=" bg-[#000000] p-3 border-r-[3px] border-green sm:p-1">
            <YoutubeVideo
              videoUrl={multimedia[2]?.news_link.substring(
                multimedia[2]?.news_link.lastIndexOf("/") + 1
              )}
            />
          </div>
        )}

        <div className="grid grid-cols-2 gap-6 sm:grid-cols-1 sm:gap-3 ">
          {multimedia &&
            multimedia.slice(3, 9).map((item) => (
              <div
                key={item.news_id}
                className="p-3   border-r-[3px] text-white border-green bg-[#000000] grid grid-cols-2 md:grid-cols-1 sm:gap-2 hover:text-green "
              >
                <Link to={`/details/${item?.news_id}`}>
                  <LazyLoadImage
                    src={`https://api.kbclive.net/uploads/${item?.image}`}
                    alt=""
                    effect="blur"
                    placeholderSrc="path/to/placeholder-image.jpg" // Optional placeholder image while loading
                  />
                </Link>
                <div className="px-5 py-3 flex flex-col sm:px-1 ">
                  <Link
                    to={`/details/${item.news_id}`}
                    className="font-calib-b   text-2xl hover:text-green sm:text-xl"
                  >
                    {item.news_title}
                  </Link>

                  <div className=" text-gray-300 opacity-70 mt-auto  sm:mt-2">
                    <AutherAndDate news={item} />
                  </div>
                </div>
              </div>
            ))}
        </div>
        {multimedia[9] && (
          <div className=" bg-[#000000] p-3 border-r-[3px] border-green">
            <YoutubeVideo
              videoUrl={multimedia[9]?.news_link.substring(
                multimedia[9]?.news_link.lastIndexOf("/") + 1
              )}
            />
          </div>
        )}

        <div className="grid grid-cols-2 gap-6 sm:grid-cols-1 sm:gap-3 ">
          {multimedia &&
            multimedia.slice(10, 10 + visiblemultimedias).map((item) => (
              <div
                key={item.news_id}
                className="p-3   border-r-[3px] text-white border-green bg-[#000000] grid grid-cols-2 md:grid-cols-1 sm:gap-2 hover:text-green "
              >
                <Link to={`/details/${item?.news_id}`}>
                  <LazyLoadImage
                    src={`https://api.kbclive.net/uploads/${item?.image}`}
                    alt=""
                    effect="blur"
                    placeholderSrc="path/to/placeholder-image.jpg" // Optional placeholder image while loading
                  />
                </Link>
                <div className="px-5 py-3 flex flex-col sm:px-1 ">
                  <Link
                    to={`/details/${item.news_id}`}
                    className="font-calib-b   text-2xl hover:text-green sm:text-xl"
                  >
                    {item.news_title}
                  </Link>

                  <div className=" text-gray-300 opacity-70 mt-auto  sm:mt-2">
                    <AutherAndDate news={item} />
                  </div>
                </div>
              </div>
            ))}
        </div>
        {visiblemultimedias < multimedia.length && (
          <div className="flex justify-center my-6 sm:my-2">
            <button
              onClick={handleLoadMore}
              className="px-5 py-[5px] font-calib-b  text-xl   border-2 border-green hover:bg-green text-white"
            >
              زیاتر ببــــینە
            </button>
          </div>
        )}
      </div>
      <div className="max-w-7xl px-5 mx-auto py-10 md:px-2">
        <div
          style={{ whiteSpace: "nowrap" }}
          className="flex items-center gap-3 mb-5"
        >
          <h1 className="text-2xl text-green font-bold font-calib-b">
            نوێترین بەرنامەکان
          </h1>
          <div className="bg-green w-full h-[3px]"></div>
        </div>
        <div className="grid grid-cols-3 gap-x-5 gap-y-8 md:grid-cols-2 xs:grid-cols-1 md:gap-3">
          {news &&
            news.map((program) => (
              <div key={program.news_id} className=" p-2 bg-[#000] text-white">
                <div className=" hover:text-green">
                  <Link to={`/details/${program.news_id}`}>
                    <LazyLoadImage
                      src={`https://api.kbclive.net/uploads/${program?.image}`}
                      alt=""
                      effect="blur"
                      placeholderSrc="path/to/placeholder-image.jpg" // Optional placeholder image while loading
                    />
                  </Link>

                  <div className=" px-4 py-2 flex flex-col">
                    {program?.category_id === 4 && (
                      <h1 className=" text-lg text-white   font-calib-r ">
                        {program?.program_kind}
                      </h1>
                    )}
                    <div className=" -mt-[4px] text-green font-calib-b mb-3">
                      <AutherAndDate news={program} />
                    </div>
                    <Link
                      to={`/details/${program.news_id}`}
                      className="text-2xl font-calib-b  hover:text-green sm:text-xl"
                    >
                      {program.news_title}
                    </Link>
                  </div>
                </div>
              </div>
            ))}
        </div>

        <div className="text-center mt-5">
          <Link
            to="/program"
            className="px-5 py-[5px] font-calib-b  text-xl   border-2 border-green hover:bg-green text-white"
            onClick={handleLoadMoreLastnews}
          >
            هەمووی ببینە
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MultiMedia;
