import { BsPlayFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import YoutubeThumnail from "../components/youtube-thumnail";
import bg from "../images/bg.jpg";
import useFetch from "../hooks/usefetch";
import { useEffect } from "react";
import YoutubeThumnailForMultimedia from "../components/youtube-thumnail2";

const MultiMediaLayout = () => {
  const multimediaroute = "https://api.kbclive.net/last-five-multimedia";

  const { getData: getMulyimedia, data: news } = useFetch();

  useEffect(() => {
    getMulyimedia(multimediaroute);
  }, []);
  let multiMediaNumber = 1;
  return (
    <div
      style={{
        background: `  linear-gradient(
            rgba(0, 0, 0, 0.1), 
            rgba(0, 0, 0, 0.1)
          ), url(${bg})`,

        backgroundSize: "cover",
      }}
      className="flex mb-5  flex-col items-center justify-center border-t-8 border-green"
    >
      <h1 className=" bg-green text-white pt-6 pb-2 px-4  mb-1 font-calib-b text-4xl">
        مەڵتی میدیا
      </h1>
      <div className=" max-w-4xl   py-20 md:gap-10">
        {news &&
          Array.isArray(news) &&
          news.length > 0 &&
          news.slice(0, 5).map((newss) => (
            <div key={newss.news_id}>
              <div className="px-5 grid grid-cols-2 items-center gap-20 mb-14 sm:grid-cols-1 sm:gap-2 sm:px-2">
                <div className="  h-56 object-cover">
                  <YoutubeThumnailForMultimedia
                    link={newss?.news_id}
                    image={newss?.image}
                  />
                </div>

                <div className=" border-r-4 border-green pr-10 h-36 sm:border-r-0 sm:pr-0 sm:pb-3 sm:border-b-2 sm:h-fit sm:text-center">
                  <h1 className=" text-green text-3xl font-calib-b mb-2  sm:mb-2">
                    {multiMediaNumber++}
                  </h1>
                  <Link
                    to={`details/${newss?.news_id}`}
                    className="text-3xl  text-white font-calib-b  hover:text-green sm:text-2xl "
                  >
                    {newss?.news_title}
                  </Link>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default MultiMediaLayout;
