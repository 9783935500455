import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";

import "./swiperhome.css";

import { Autoplay, Pagination } from "swiper";
import { useNavigate } from "react-router-dom";

export default function Swip({ news }) {
  const navigate = useNavigate();
  const lastNews = news?.slice(0, 3);
  const swiperNavNextRef = useRef(null);
  const swiperNavPrevRef = useRef(null);
  return (
    <>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={{
          prevEl: swiperNavPrevRef.current,
          nextEl: swiperNavNextRef.current,
        }}
        modules={[Autoplay, Pagination]}
        className="home-swiper"
      >
        {lastNews.map((news) => (
          <SwiperSlide
            key={news.news_id}
            style={{
              background: `  linear-gradient(
               rgba(0, 0, 0, 0.1), 
                rgba(0, 0, 0, 0.1)
                ),url(${`https://api.kbclive.net/uploads/${news?.image}`})`,

              backgroundSize: "cover",
            }}
            onClick={() => navigate(`/details/${news.news_id}`)}
          >
            {" "}
            <div className="  absolute bottom-0 w-full  py-2 bg-green/[0.6] cursor-pointer">
              <p
                onClick={() => navigate(`/details/${news.news_id}`)}
                className=" text-center font-calib-b text-2xl text-white xs:text-xl"
              >
                {news?.news_title.length > 35
                  ? `${news?.news_title.slice(0, 35)}...`
                  : news?.news_title}
              </p>
            </div>
            <div
              className=" h-[75vh] w-full relative bg-center bg-no-repeat cursor-pointer md:h-[50vh] xs:h-[30vh]"
              onClick={() => navigate(`/details/${news.news_id}`)}
            ></div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}
