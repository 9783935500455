import { useState, useEffect } from "react";
import useFetch from "../hooks/usefetch";

import { Helmet } from "react-helmet";

import ProgramSwiper from "../components/programswiper";
import YoutubeThumnail from "../components/youtube-thumnail";
import { Link, Outlet } from "react-router-dom";
import MultiMediaLayout from "../layouts/MultiMedia";
import Loading from "../components/lodaing/loading";
import AutherAndDate from "../components/dateandauther";
import { LazyLoadImage } from "react-lazy-load-image-component";
const Program = () => {
  const newsUrl = "https://api.kbclive.net/category/4";

  const [visibleprograms, setVisibleprograms] = useState(9);
  const { getData: getprogram, data: program, isloading } = useFetch();

  useEffect(() => {
    getprogram(newsUrl);
  }, []);

  const handleLoadMore = () => {
    setVisibleprograms((prevCount) => prevCount + 8);
  };

   
   if (isloading) {
     return <Loading />;
   }
   
  return (
    <div>
      <Helmet>
        <title>بەرنامەکان</title>
      </Helmet>

      <ProgramSwiper />

      <div className=" py-5 ">
        <div className="section">
          <div
            style={{ whiteSpace: "nowrap" }}
            className="flex items-center gap-3 mb-5"
          >
            <h1 className="text-2xl  text-green font-bold font-calib-b">
              بەرنامەکان
            </h1>
            <div className="bg-green w-full h-[3px]"></div>
          </div>
          <div className=" mb-5  grid grid-cols-3 gap-5 md:grid-cols-2 xs:grid-cols-1">
            {program &&
              program?.slice(0, visibleprograms).map((program) => (
                <div key={program.news_id} className=" p-2 bg-gray-100">
                  <div className=" hover:text-green">
                    <Link to={`/details/${program.news_id}`}>
                      <LazyLoadImage
                        src={`https://api.kbclive.net/uploads/${program?.image}`}
                        alt=""
                        effect="blur"
                        placeholderSrc="path/to/placeholder-image.jpg" // Optional placeholder image while loading
                      />
                    </Link>

                    <div className=" px-4 py-2 flex flex-col ">
                      {program?.category_id === 4 && (
                        <h1 className=" text-lg   font-calib-r text-black ">
                          {program?.program_kind}
                        </h1>
                      )}
                      <div className=" -mt-[4px] text-green font-calib-b mb-3">
                        <AutherAndDate news={program} />
                      </div>
                      <Link
                        to={`/details/${program.news_id}`}
                        className="text-2xl font-calib-b  hover:text-green sm:text-xl"
                      >
                        {program.news_title}
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          {visibleprograms < program.length && (
            <div className="text-center">
              <button
                className="px-5 py-1 font-calib-b  text-xl  text-black border-2 border-green hover:bg-green hover:"
                onClick={handleLoadMore}
              >
                زیاتر ببــــینە
              </button>
            </div>
          )}
        </div>
      </div>

      <MultiMediaLayout />
    </div>
  );
};

export default Program;
