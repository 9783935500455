import { Link, useParams } from "react-router-dom";
import useFetch from "../hooks/usefetch";
import { useEffect, useState } from "react";
import YoutubeVideo from "../components/youtubevideo";
import { Helmet } from "react-helmet";
import {
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton,
} from "react-share";

import {
  FaFacebookF,
  FaSnapchat,
  FaTelegramPlane,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";

import { AiFillInstagram } from "react-icons/ai";
import NewsTitle from "../components/reportFront";
import YoutubeThumnailForMultimedia from "../components/youtube-thumnail2";
import { BsFillCalendar2Fill } from "react-icons/bs";
import Loading from "../components/lodaing/loading";
import AutherAndDate from "../components/dateandauther";
import { LazyLoadImage } from "react-lazy-load-image-component";
const NewsDetails = () => {
  const { id } = useParams();
  const route = `https://api.kbclive.net/news/${id}`;
  const articleroute = "https://api.kbclive.net/last-ten-articles";
  const multimediaroute = "https://api.kbclive.net/last-five-multimedia";
  const programroute = "https://api.kbclive.net/last-six-program";
  const { getData: getArticle, data: article } = useFetch();
  const { getData: getMulyimedia, data: multimedia } = useFetch();
  const { getData, data: news, isloading } = useFetch();
  const { getData: getProgram, data: program } = useFetch();
  const currentUrl = window.location.href;

  useEffect(() => {
    getData(route);
    getArticle(articleroute);
    getMulyimedia(multimediaroute);
    getProgram(programroute);
  }, [id]);

  if (isloading) {
    return <Loading />;
  }

  return (
    <div>
      <Helmet>
        <title>{news.news_title}</title>
      </Helmet>
      <div className="py-10 max-w-7xl mx-auto px-2 sm:px-2">
        <div className="grid md:grid-cols-1 grid-cols-3 gap-5">
          <div className="col-start-1 col-end-3 md:col-span-1">
            <div className="mb-6">
              {news?.news_link ? (
                <div className="">
                  <YoutubeVideo
                    videoUrl={news?.news_link.substring(
                      news?.news_link.lastIndexOf("/") + 1
                    )}
                  />
                </div>
              ) : (
                <div>
                  <img
                    src={`https://api.kbclive.net/uploads/${news?.image}`}
                    className=""
                  />
                  {/* <div className="h-10 bg-black w-full text-white flex px-1  items-center gap-5  sm:gap-1">
                    <div className=" bg-white px-2 py-1 items-center flex gap-4">
                      <i>
                        <BsFillCalendar2Fill className=" duration-300  text-xl  text-black" />
                      </i>
                      <span className=" font-calib-r text-black">
                        {news?.news_date?.substring(0, 10)}
                      </span>
                    </div>
                    <div className=" flex gap-5 text-white items-center sm:gap-2">
                      <p className=" font-calib-r text-lg">بەشی بکە لە </p>
                      <div className="flex gap-5 sm:justify-center">
                        <FacebookShareButton
                          title={news.news_title}
                          url={currentUrl}
                          media={`https://api.kbclive.net/uploads/${news?.image}`}
                        >
                          <FaFacebookF className="duration-300  text-xl text-white hover:text-green" />
                        </FacebookShareButton>
                        <TelegramShareButton
                          title={news.news_title}
                          url={currentUrl}
                          media={`https://api.kbclive.net/uploads/${news?.image}`}
                        >
                          <FaTelegramPlane className="duration-300  text-xl text-white hover:text-green" />
                        </TelegramShareButton>
                        <TwitterShareButton
                          title={news.news_title}
                          url={currentUrl}
                          media={`https://api.kbclive.net/uploads/${news?.image}`}
                        >
                          <FaTwitter className="duration-300  text-xl text-white hover:text-green" />
                        </TwitterShareButton>
                      </div>
                    </div>
                  </div> */}
                  <div className="mt-1">
                    <AutherAndDate news={news} />
                  </div>
                </div>
              )}
            </div>
            <h1 className="font-calib-b -mt-2 text-3xl mb-1">
              {news.news_title}
            </h1>

            <div
              className="pl-20 font-calib-r mb-5 text-lg md:pl-0"
              dangerouslySetInnerHTML={{
                __html: `${news?.news_description}`,
              }}
            />
          </div>
          <div className=" md:col-span-1">
            <div className="bg-gray-100 flex flex-col gap-2 p-1 mb-5">
              <h1 className="font-calib-b text-3xl px-2 py-4 text-green">
                نوێترین وتارەکان
              </h1>
              {article?.slice(0, 8).map((article, index) => (
                <div
                  key={index}
                  className={`flex gap-3 px-2 py-4 ${
                    index === 0
                      ? "bg-green text-white hover:bg-white"
                      : "bg-white hover:bg-green"
                  }`}
                >
                  <div className="bg-black w-8 h-8 flex justify-center items-center">
                    <p className="font-mono text-white">{index + 1}</p>
                  </div>
                  <Link
                    to={`/details/${article.news_id}`}
                    className={`text-xl font-calib-b ${
                      index === 0
                        ? "text-white hover:text-gray-500"
                        : "text-gray-500 hover:text-white"
                    }`}
                  >
                    {article.news_title.length > 45
                      ? `${article.news_title.slice(0, 40)}...`
                      : article.news_title}
                  </Link>
                </div>
              ))}
            </div>
            <div className="bg-gray-100 p-2">
              <h1 className="font-calib-b text-3xl px-2 py-4 text-green">
                مەڵتی میدیا
              </h1>
              {multimedia?.map((multi) => (
                <div className="mb-8">
                  <div key={multi.news_id} className="h-56">
                    <YoutubeThumnailForMultimedia
                      link={multi?.news_id}
                      image={multi?.image}
                    />
                  </div>
                  <Link
                    to={`/details/${multi.news_id}`}
                    className="block px-2 mt-2 text-xl font-calib-b hover:text-green"
                  >
                    {multi.news_title}
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="mt-10">
          <div
            style={{ whiteSpace: "nowrap" }}
            className="flex items-center gap-3 mb-5"
          >
            <h1 className="text-2xl text-green font-bold font-calib-b">
              نوێترین بەرنامەکان
            </h1>
            <div className="bg-green w-full h-[3px]"></div>
          </div>
          <div className="grid grid-cols-3 gap-5 md:grid-cols-2 xs:grid-cols-1 ">
            {program?.map((program) => (
              <div key={program.news_id} className=" p-2 bg-gray-100">
                <div className=" hover:text-green">
                  <Link to={`/details/${program.news_id}`}>
                    <LazyLoadImage
                      src={`https://api.kbclive.net/uploads/${program?.image}`}
                      alt=""
                      effect="blur"
                      placeholderSrc="path/to/placeholder-image.jpg" // Optional placeholder image while loading
                    />
                  </Link>

                  <div className=" px-4 py-2 flex flex-col ">
                    {program?.category_id === 4 && (
                      <h1 className=" text-lg   font-calib-r text-black ">
                        {program?.program_kind}
                      </h1>
                    )}
                    <div className=" -mt-[4px] text-green font-calib-b mb-3">
                      <AutherAndDate news={program} />
                    </div>
                    <Link
                      to={`/details/${program.news_id}`}
                      className="text-2xl font-calib-b  hover:text-green sm:text-xl"
                    >
                      {program.news_title}
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className=" items-center flex justify-center mt-5">
            <Link
              className=" px-3 py-1 text-xl font-calib-r border-2 border-green"
              to="/program"
            >
              هەموو بەرنامەکان ببینە
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsDetails;
